export const privacyPageCopy = {
  headerText: {
    paragraph: (
      <>
        <span
          style={{ display: "block", marginBottom: "3rem", fontWeight: "bold" }}
        >
          {`Effective date: 10 October 2023`}
        </span>
        <span style={{ marginBottom: "3rem" }}>
          {`Biom Studio and its affiliates ("Biom", “us”, “our” or "we") are
          dedicated to protecting the privacy rights of our users ("users" or
          "you"). This Privacy Policy (the “Policy”) describes the ways we
          collect, store, use, and manage the information that you provide or we
          collect in connection with our websites, including
          https://www.biom.studio/(the “Sites”) or any Biom game provided on a
          mobile platform (for example, iOS and Android)(collectively, the
          "Service"). Please note that the scope of this Policy is limited to
          information collected or received by Biom through your use of the
          Service. Biom is not responsible for the actions of third party people
          or companies, the content of their sites, the use of information you
          provide to them, or any products or services they may offer. Any link
          to those sites does not constitute our sponsorship of, or affiliation
          with, those people or companies.`}
        </span>
        <br />
        <br />
        {`By using the Service, you are expressing your agreement to this Policy
        and the processing of your data, including your personal information, in
        the manner provided in this Policy. If you do not agree to these terms,
        please do not use the Service.`}
      </>
    ),
  },
  subContent: [
    {
      subtitle: `Information Collection and Use`,
      subParagraphs: [
        `Biom collects information as described below. Biom's primary goals in collecting and using information are to provide Services to you, improve our Service, conduct research and create reports for internal use.`,
      ],
    },
    {
      subtitle: `Biom's Sites`,
      subParagraphs: [
        <>
          <span style={{ marginBottom: "2rem" }}>
            {`While you are browsing Biom's Sites, your computer’s operating
            system, Internet Protocol (IP) address, access times, browser type
            and language and referring Web site addresses may be logged
            automatically.`}
          </span>
        </>,
        <>
          <span style={{ marginBottom: "2rem" }}>
            {`We may use this information to monitor and analyze your use of the
            Service.`}
          </span>
        </>,
        <>
          <span style={{ display: "block" }}>{`Forums`}</span>
          {`Biom may at times offer you the ability to post content to a message
          board, chat room or within Biom’s applications or other forums such as
          Biom’s Facebook page (“Forums"). You should be aware that any visitor
          to the Forums may read your postings on the Forums. Furthermore, any
          information that you may post to a Forum, including your username or
          other account profile information, will be disclosed and available to
          all users and viewers of that Forum, and will therefore no longer be
          private. Biom cannot guarantee the security of such information that
          is disclosed or communicated online in public areas such as the
          Forums, and you provide such information at your own risk. Biom does
          not endorse, or regularly monitor any Forum. You acknowledge and agree
          that Biom has no responsibility for the accuracy or availability of
          information provided on any Forum. Biom reserves the right at any
          time, in its sole discretion, to remove any content that a user may
          post on any Forum and restrict their access to any Forum.`}
        </>,
      ],
    },
    {
      subtitle: `iOS and Android Platforms`,
      subParagraphs: [
        <>
          <span style={{ marginBottom: "2rem" }}>
            {`When you use any of the Biom games or applications on a mobile
            platform, we may collect and record anonymous statistics such as
            hardware type, the version of your operating system (“OS”), and
            downloads by country. This information is aggregated and not
            associated to specific users. This information allows us to better
            target our improvements and bug fixes to the largest number of our
            users possible.`}
          </span>
        </>,
        <>
          <span style={{ marginBottom: "2rem" }}>
            {`We do not store your locational information, photos or any other
            information you enter in our software.`}
          </span>
        </>,
        <>
          <span style={{ display: "block" }}>
            {`Sharing via Facebook and Flickr`}
          </span>
          {`We do not collect your Facebook and Flickr login details or other
          information when you connect and share via our software.`}
        </>,
      ],
    },
    {
      subtitle: `Customer Service`,
      subParagraphs: [
        `We may collect your email address when you contact our customer service group and we may use that email address to contact you about your gaming experience with Biom games and notify you about company news and promotions.`,
      ],
    },
    {
      subtitle: `Third Party Services`,
      subParagraphs: [
        <>
          <span style={{ marginBottom: "2rem" }}>
            {`Our services may contain third party tracking tools from our service
            providers, examples of which include Google Analytics and Flurry.
            Such third parties may use cookies, APIs, and SDKs in our services
            to enable them to collect and analyze user information on our
            behalf. The third parties may have access to information such as
            your device identifier, MAC address, IMEI, locale (specific location
            where a given language is spoken), geo-locationinformation, and IP
            address. These statistics are collected as anonymous data and
            aggregated for our use.`}
          </span>
        </>,
        <>
          <span style={{ marginBottom: "2rem" }}>
            {`You can opt-out of Flurry Analytics tracking by clicking`}
            <span style={{ display: "block" }}>
              www.flurry.com/user-opt-out.html
            </span>
          </span>
        </>,
        <>
          And you can opt-out of Google Analytics with this browser add-on tool:
          <span style={{ display: "block" }}>
            https://tools.google.com/dlpage/gaoptout
          </span>
        </>,
      ],
    },
    {
      subtitle: `Advertisements`,
      subParagraphs: [
        `You may see our games advertised in other services. After clicking on one of these advertisements and installing our game, you will become a user of our Service. In order to verify the installs, a device identifier may be shared with the advertiser.`,
      ],
    },
    {
      subtitle: `Disclosure of Your information`,
      subParagraphs: [
        <>
          <span style={{ marginBottom: "2rem" }}>
            {`Biom does not share your personal information except as approved by
            you or as described below:`}
          </span>
        </>,
        <>
          <span className="bulletPoint">
            <span>&#9702; </span>
            <span>
              Biom may engage other companies and individuals to perform
              services on our behalf. Example of these services include
              analyzing data. These agents and service providers may have access
              to your personal information in connection with the performance of
              services for Biom
            </span>
          </span>
        </>,
        <>
          <span className="bulletPoint">
            <span>&#9702;</span>
            <span>
              {`We may release your information as permitted by law or when we
              believe that release is appropriate to comply with the law;
              enforce or apply our rights; or protect the rights, property, or
              safety of us or our users, or others. This includes exchanging
              information with other companies and organizations for fraud
              protection.`}
            </span>
          </span>
        </>,
        <>
          <span className="bulletPoint">
            <span>&#9702;</span>
            <span>
              {`We may share aggregate or anonymous information about you with
              advertisers, publishers, business partners, sponsors, and other
              third parties.`}
            </span>
          </span>
        </>,
      ],
    },
    {
      subtitle: `Changes to the Policy`,
      subParagraphs: [
        `We reserve the right to change this Policy at any time. If we decide to change this Policy, we will post those changes on this page and update the date at the top of the page. The changes will be effective when posted. Please check this page on a regular basis so that you remain aware of what information we collect and how we use it.`,
      ],
    },
    {
      subtitle: `Security`,
      subParagraphs: [
        <>
          <span style={{ marginBottom: "2rem" }}>
            {`Biom takes reasonable measures to protect your information from
            unauthorized access or against loss, misuse or alteration by third
            parties.`}
          </span>
        </>,
        `Although we make good faith efforts to store the information collected on the Service in a secure operating environment that is not available to the public, we cannot guarantee the absolute security of that information during its transmission or its storage on our systems. Further, while we attempt to ensure the integrity and security of our network and systems, we cannot guarantee that our security measures will prevent third-party “hackers" from illegally obtaining access to this information. We do not warrant or represent that your information will be protected against, loss, misuse, or alteration by third parties.`,
      ],
    },
    {
      subtitle: `Opting Out of Marketing`,
      subParagraphs: [
        `You may opt-out of receiving promotional emails from us by following the instructions in those emails.`,
      ],
    },
  ],
};

export const revisedSiteCopy = {
  landingPage: {
    heading: {
      mobile: (
        <>
          {`Welcome To `}
          <span>{`The Future `}</span>
          <span>{`of Fandom`}</span>
        </>
      ),
      desktop: (
        <>
          {`Welcome To The `}
          <span>{`Future of Fandom`}</span>
        </>
      ),
    },
    subheading: {
      mobile: (
        <>
          <span>{`We foster artist-fan bonds with bundle sales, offering `}</span>
          <span>{`exclusive content, direct artist connections, and unique `}</span>
          <span>{`experiences through gaming and live events. Explore with us!`}</span>

          <span>
            {`Are you an artist, subscribe now `}
            <span>{`and get early access`}</span>
          </span>
        </>
      ),
      desktop: (
        <>
          <span>{`We foster artist-fan bonds with bundle sales, offering exclusive content, direct artist `}</span>
          <span>{`connections, and unique experiences through gaming and live events. Explore with us!`}</span>

          <span>{`Are you an artist, Subscribe now and get early access`}</span>
        </>
      ),
    },
    cta: `Are you a fan, download here`,
    successText: `Success! You'll be the first to know when we launch.`,
    placeholder: "Email Address",
    buttonName: "Subscribe",
  },
};
